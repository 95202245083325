<div class="bg-img" [ngStyle]="{'background-image': 'url(' + (backgroundImage | pipeWebp) +')','background-size': backgroundSize}"></div>
<div class="main-content">
  <div class="company-logo">
    <div class="logo" [ngStyle]="{'background-image': 'url(' + (logo | pipeWebp) +')'}"></div>
    <h1 [ngStyle]="{'color': textColor }">{{ customerName }}</h1>
  </div>

  <div
    (click)="toGroup()"
    *ngIf="groupLogo"
    class="group-logo">
    <div class="logo"
      [ngStyle]="{'background-image': 'url(' + (groupLogo | pipeWebp) +')'}"></div>
  </div>

  <div class="company-search mat-subheading-1" *ngIf="hasSearch">
    <div class="search" [ngStyle]="{'border-bottom': 'solid 1px ' + textColor}">
      <a [routerLink]="[ '/search' ]" routerLinkActive="active">
      <span [ngStyle]="{'color': textColor }">{{ 'search.title' | translate}}</span>
      <mat-icon [ngStyle]="{'color': textColor}" aria-hidden="false" aria-label="back">search</mat-icon>
      </a>
    </div>
  </div>

  <div *ngIf="menuType===0" [ngClass]="{'no-search': !hasSearch}" class="menus mat-subheading-1 test">
    <!--/main-menu-community do not change or remove this line-->
<ul>
      <!--/main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS4" *ngIf="show_news4">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews4() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line/-->

</ul>
    <!--/main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line-->
    <ul [ngClass]="'main-menu-' + MenuFromGRPC.MENUCUSTOMMENU" *ngIf="menus$ | async as menus ">
        <li *ngFor="let menu of menus">
          <div [ngStyle]="{'background-color': backgroundColor}"></div>
          <a [ngStyle]="{'color': textColor}" target="_blank" [href]="menu.getUrl()" routerLinkActive="router-link-active">
            {{ menu.getName() }}</a>
        </li>
    </ul>
    <!--main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line/-->

<ul>
      <!--/main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS3" *ngIf="show_news3">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews3() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS" *ngIf="show_news">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS5" *ngIf="show_news5">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews5() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUALBUM"  *ngIf="show_album">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/albums']" routerLinkActive="router-link-active">
          {{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUROOMRESERVATION" *ngIf="show_room_reservation">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
          {{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS6" *ngIf="show_news6">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews6() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS7" *ngIf="show_news7">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews7() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS8" *ngIf="show_news8">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews8() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS9" *ngIf="show_news9">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews9() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUPLACE" *ngIf="show_place">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
          {{ menuAlias?.getPlace() ||  ('places.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS2" *ngIf="show_news2">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
          {{ menuAlias?.getNews2() ||  ('news.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUGOLFCLASS" *ngIf="show_golf_teaching_class">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
          {{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUMEMBER" *ngIf="show_member">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">{{ menuAlias?.getMember() || ('members.title' | translate) }}</a>

      </li>
      <!--main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUEVENT" *ngIf="show_event">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/events']" routerLinkActive="router-link-active">
          {{ menuAlias?.getEvent() ||  ('events.title' | translate) }}
        </a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOP" *ngIf="show_shop">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/shop']"
          routerLinkActive="router-link-active">
          <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">
            {{ menuAlias?.getShop() ||  ('shop.title' | translate) }}
            <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
          </span>
        </a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM" *ngIf="show_stream">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
          {{ menuAlias?.getStream() ||  ('streams.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM2" *ngIf="show_stream2">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
          {{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM3" *ngIf="show_stream3">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
          {{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM4" *ngIf="show_stream4">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
          {{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUCOMPETITION" *ngIf="show_competition">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
          {{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENULEADERBOARD" *ngIf="show_leader">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
          {{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUFORUM" *ngIf="show_forum">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/forum']" routerLinkActive="router-link-active">
          <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">
            {{ menuAlias?.getForum() ||  ('forum.title' | translate) }}
            <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
          </span>
        </a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENURESTAURANT" *ngIf="show_restaurant">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" (click)="open_restaurant()"
          routerLinkActive="router-link-active">
          <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">
            {{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}
            <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
          </span>
        </a>
      </li>
      <!--main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUBENEFIT"  *ngIf="show_benefit">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
          {{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOPPROFILE" *ngIf="show_shopprofile">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/about']" routerLinkActive="router-link-active">
          {{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUDOCUMENT" *ngIf="show_document">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/documents']" routerLinkActive="router-link-active">
          {{ menuAlias?.getDocument() ||  ('document.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENUCPSLINK" *ngIf="show_cpsurl">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" target="_blank" [href]="cpsurl" routerLinkActive="router-link-active">
          {{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENULINK do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK" *ngIf="show_links">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
          {{ menuAlias?.getLink() ||  ('links.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENULINK do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK2" *ngIf="show_links2">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
          {{ menuAlias?.getLink2() ||  ('links.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK3" *ngIf="show_links3">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
          {{ menuAlias?.getLink3() ||  ('links.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line/-->

      <!--/main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line-->
      <li [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK4" *ngIf="show_links4">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <a [ngStyle]="{'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
          {{ menuAlias?.getLink4() ||  ('links.title' | translate) }}</a>
      </li>
      <!--main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line/-->

</ul>
    <!--main-menu-community do not change or remove this line/-->
</div>

<div *ngIf="menuType === 1" class="menus-photo mat-subheading-1">

  <!--//main-menu-community do not change or remove this line-->
    <!--/main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS4" class="menu-col" *ngIf="show_news4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews4photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews4() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCUSTOMMENU" class="menu-col" *ngFor="let menu of menus$ | async menus">
      <a target="_blank" [href]="menu.getUrl()" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menu.getPhoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menu.getName() }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS3" class="menu-col" *ngIf="show_news3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews3photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews3() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS" class="menu-col" *ngIf="show_news">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNewsphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS5" class="menu-col" *ngIf="show_news5">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews5photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews5() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUALBUM" class="menu-col" *ngIf="show_album">
      <a [routerLink]="['/albums']" routerLinkActive="router-link-active">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAlbumphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}
        </span></a>

    </div>
    <!--main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUROOMRESERVATION" class="menu-col" *ngIf="show_room_reservation">
      <a [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRoomreservationphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS6" class="menu-col" *ngIf="show_news6">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews6photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews6() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS7" class="menu-col" *ngIf="show_news7">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews7photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews7() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS8" class="menu-col" *ngIf="show_news8">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews8photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews8() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS9" class="menu-col" *ngIf="show_news9">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews9photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews9() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line/-->
    
    <!--/main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUPLACE" class="menu-col" *ngIf="show_place">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getPlacephoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getPlace() ||  ('places.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS2" class="menu-col" *ngIf="show_news2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews2photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews2() ||  ('news.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUGOLFCLASS" class="menu-col" *ngIf="show_golf_teaching_class">
      <a [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getGolfclasslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUMEMBER" class="menu-col" *ngIf="show_member">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getMemberphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getMember() || ('members.title' | translate) }}
      </span></a>

    </div>
    <!--main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUEVENT" class="menu-col" *ngIf="show_event" >
      <a [routerLink]="['/events']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getEventphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getEvent() ||  ('events.title' | translate) }}
        </span>
        </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOP" class="menu-col" *ngIf="show_shop">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/shop']"
        routerLinkActive="router-link-active">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getShopphoto() +')'}"></div>
        <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">
          {{ menuAlias?.getShop() ||  ('shop.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
        </span>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM" class="menu-col" *ngIf="show_stream">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream() ||  ('streams.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM2" class="menu-col" *ngIf="show_stream2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM3" class="menu-col" *ngIf="show_stream3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM4" class="menu-col" *ngIf="show_stream4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCOMPETITION" class="menu-col" *ngIf="show_competition">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCompetitionphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}
        </span></a>

    </div>
    <!--main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULEADERBOARD" class="menu-col" *ngIf="show_leader">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLeaderboardphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}
        </span></a>

    </div>
    <!--main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUFORUM" class="menu-col" *ngIf="show_forum">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/forum']"
        routerLinkActive="router-link-active">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getForumphoto() +')'}"></div>
        <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">
          {{ menuAlias?.getForum() ||  ('forum.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
        </span>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENURESTAURANT" class="menu-col" *ngIf="show_restaurant">
      <a [ngStyle]="{'color': textColor}" (click)="open_restaurant()"
        routerLinkActive="router-link-active">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRestaurantphoto() +')'}"></div>
        <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">
          {{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
        </span>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUBENEFIT" class="menu-col" *ngIf="show_benefit">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getBenefitphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOPPROFILE" class="menu-col" *ngIf="show_shopprofile">
      <a [routerLink]="['/about']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAboutusphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUDOCUMENT" class="menu-col" *ngIf="show_document">
      <a [routerLink]="['/documents']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getDocumentphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getDocument() ||  ('document.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line/-->
    
    <!--/main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCPSLINK" class="menu-col" *ngIf="show_cpsurl">
      <a [href]="cpsurl" target="_blank" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCpslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK" class="menu-col" *ngIf="show_links">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink() ||  ('links.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK2" class="menu-col" *ngIf="show_links2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink2() ||  ('links.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK3" class="menu-col" *ngIf="show_links3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink3() ||  ('links.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK4" class="menu-col" *ngIf="show_links4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink4() ||  ('links.title' | translate) }}
        </span></a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line/-->

    <!--main-menu-community do not change or remove this line//-->
  </div>

  <div *ngIf="menuType===2" [ngClass]="{'not4': menucount < 4}" class="menus-tablet mat-subheading-1">
    <!--///main-menu-community do not change or remove this line-->
    <!--/main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS4" class="menu-col" *ngIf="show_news4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews4photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews4() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS4 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCUSTOMMENU" class="menu-col" *ngFor="let menu of menus$ | async menus">
      <a target="_blank" [href]="menu.getUrl()" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menu.getPhoto()  +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menu.getName() }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUCUSTOMMENU do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS3" class="menu-col" *ngIf="show_news3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews3photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews3() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS" class="menu-col" *ngIf="show_news">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNewsphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS5" class="menu-col" *ngIf="show_news5">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news5']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews5photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews5() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS5 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUALBUM" class="menu-col" *ngIf="show_album">
      <a [routerLink]="['/albums']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAlbumphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAlbum() ||  ('albums.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUALBUM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUROOMRESERVATION" class="menu-col" *ngIf="show_room_reservation">
      <a [routerLink]="['/guest/reservations']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRoomreservationphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getRoomreservation() ||  ('guest.menu-reservation' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUROOMRESERVATION do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS6" class="menu-col" *ngIf="show_news6">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news6']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews6photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews6() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS6 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS7" class="menu-col" *ngIf="show_news7">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news7']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews7photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews7() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS7 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS8" class="menu-col" *ngIf="show_news8">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news8']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews8photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews8() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS8 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS9" class="menu-col" *ngIf="show_news9">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news9']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews9photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews9() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS9 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUPLACE" class="menu-col" *ngIf="show_place">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/places']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getPlacephoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getPlace() ||  ('places.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUPLACE do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUNEWS2" class="menu-col" *ngIf="show_news2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/news2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getNews2photo() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getNews2() ||  ('news.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUNEWS2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUGOLFCLASS" class="menu-col" *ngIf="show_golf_teaching_class">
      <a [routerLink]="['/golf_class']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getGolfclasslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getGolfclasslink() ||  ('golf_class.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUGOLFCLASS do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUMEMBER" class="menu-col" *ngIf="show_member">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/members']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getMemberphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getMember() || ('members.title' | translate) }}
      </span>
      </div>
    </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUMEMBER do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUEVENT" class="menu-col" *ngIf="show_event" >
      <a [routerLink]="['/events']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getEventphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getEvent() ||  ('events.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUEVENT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOP" class="menu-col" *ngIf="show_shop">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/shop']"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getShopphoto() +')'}"></div>
        <span [class]="'shop-menu' + (cartBadge ? '' : ' x')">
          {{ menuAlias?.getShop() ||  ('shop.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ cartBadge }}" *ngIf="cartBadge">shopping_cart</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSHOP do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM" class="menu-col" *ngIf="show_stream">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM2" class="menu-col" *ngIf="show_stream2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream2() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM3" class="menu-col" *ngIf="show_stream3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream3() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSTREAM4" class="menu-col" *ngIf="show_stream4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/streams4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getStreamphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getStream4() ||  ('streams.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSTREAM4 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCOMPETITION" class="menu-col" *ngIf="show_competition">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/competitions']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCompetitionphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCompetition() ||  ('competitions.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUCOMPETITION do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULEADERBOARD" class="menu-col" *ngIf="show_leader">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/leaderboard']" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLeaderboardphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLeaderboard() ||  ('leaderboard.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULEADERBOARD do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUFORUM" class="menu-col" *ngIf="show_forum">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/forum']"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getForumphoto() +')'}"></div>
        <span [class]="'forum-menu' + (forumBadge ? '' : ' x')">
          {{ menuAlias?.getForum() ||  ('forum.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ forumBadge }}" *ngIf="forumBadge">chat</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUFORUM do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENURESTAURANT" class="menu-col" *ngIf="show_restaurant">
      <a [ngStyle]="{'color': textColor}" (click)="open_restaurant()"
        routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getRestaurantphoto() +')'}"></div>
        <span [class]="'restaurant-menu' + (mealBadge ? '' : ' x')">
          {{ menuAlias?.getRestaurant() ||  ('menus.title' | translate) }}
          <mat-icon [class]="'badge'" matBadgeColor="warn" matBadge="{{ mealBadge }}" *ngIf="mealBadge">restaurant</mat-icon>
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENURESTAURANT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUBENEFIT" class="menu-col" *ngIf="show_benefit">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/benefits']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getBenefitphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getBenefit() ||  ('benefits.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUBENEFIT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUSHOPPROFILE" class="menu-col" *ngIf="show_shopprofile">
      <a [routerLink]="['/about']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getAboutusphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getAboutus() ||  ('about.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUSHOPPROFILE do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUDOCUMENT" class="menu-col" *ngIf="show_document">
      <a [routerLink]="['/documents']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getDocumentphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getDocument() ||  ('document.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUDOCUMENT do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENUCPSLINK" class="menu-col" *ngIf="show_cpsurl">
      <a [href]="cpsurl" target="_blank" routerLinkActive="router-link-active">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getCpslinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getCpslink() ||  ('cpsurl.title' | translate) }}
        </span>
        </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENUCPSLINK do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK" class="menu-col" *ngIf="show_links">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK2" class="menu-col" *ngIf="show_links2">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links2']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto2() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink2() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK2 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK3" class="menu-col" *ngIf="show_links3">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links3']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto3() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink3() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK3 do not change or remove this line/-->

    <!--/main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line-->
    <div [ngClass]="'main-menu-' + MenuFromGRPC.MENULINK4" class="menu-col" *ngIf="show_links4">
      <a [ngStyle]="{'color': textColor}" [routerLink]="['/links4']" routerLinkActive="router-link-active">
      <div class="menu-col-in">
      <div [ngStyle]="{'background-color': backgroundColor}"></div>
      <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + menuAlias?.getLinkphoto4() +')'}"></div>
        <span [ngStyle]="{'color': textColor}">
          {{ menuAlias?.getLink4() ||  ('links.title' | translate) }}
        </span>
      </div>
      </a>
    </div>
    <!--main-menu-MenuFromGRPC.MENULINK4 do not change or remove this line/-->

    <!--main-menu-community do not change or remove this line///-->
  </div>

  <div style="display: flex;justify-content: space-between;" class="bottom mat-subheading-1" [ngStyle]="{'background-color': backgroundColor || 'white'}">
    <div class="btn-account" *ngIf="showAccount">
      <button mat-button [ngStyle]="{'color': textColor}" (click)="account()">
        <mat-icon [ngStyle]="{'color': textColor}" aria-hidden="false" aria-label="back">person</mat-icon>
        <span>{{ 'home.My_account' | translate }}</span>
      </button>
    </div>
    <div
      *ngIf="hasAlerts"
      class="btn-alert">
      <button
        (click)="doAlert()"
        mat-flat-button [color]="'warn'">
        {{ 'alert.button' | translate }}
      </button>
    </div>
    <div class="btn-disconnect">
      <button mat-button [ngStyle]="{'color': textColor}" class="btn-disconnect" (click)="rightButton()">
        <mat-icon [ngStyle]="{'color': textColor}" class="exit" aria-hidden="false" aria-label="back">arrow_circle_down</mat-icon>
        <span>{{ (isGuest ? 'home.signin_button':'home.Disconnect') | translate }}</span>
      </button>
    </div>
  </div>

</div>

<app-alert
  (evt)="closeAlert()"
  *ngIf="showAlert"></app-alert>

<app-ads
  (evt)="closeAds()"
  [Ad]="showAd"
  *ngIf="showAd">
</app-ads>

